import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {

  constructor(private app: AppService,
    private build: FormBuilder,
    private router: Router,
    private http: HttpService,
    private activatedRoute: ActivatedRoute) { }

  quizId: number;
  ngOnInit() {
    this.formControl();

    if (this.activatedRoute.snapshot.params['id']) {
      this.quizId = this.activatedRoute.snapshot.params['id'];
      this.http.methodGet(`bo/quizmanage/get/${this.quizId}`).subscribe(res => {
        this.form.patchValue(res);
        this.form.get('UseOnDate').setValue(res['UseOnDate'].split('T')[0])
      })
    }
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group(
      {
        QuizQuestionInfo: "",
        QuizQuestionMore: "",
        DificultyLevel: 0,
        UseOnDate: "",
        Answers: this.build.array([
          this.build.group({
            QuizAnswerId:"",
            QuizAnswerInfo: "",
            QuizAnswerMore: "",
            IsCorrect: false,
            Score: 0
          }),
          this.build.group({
            QuizAnswerId:"",
            QuizAnswerInfo: "",
            QuizAnswerMore: "",
            IsCorrect: false,
            Score: 0
          })
        ])
      }
    )
  }



  getItems(form: FormGroup, key: string) {
    return (<FormArray>form.controls[key]).controls;
  }

  checkBox(items) {
    let formAnswers = this.form.get('Answers') as FormArray;
    formAnswers.controls.forEach(f => { f.get('IsCorrect').setValue(false); });
    items.get('IsCorrect').setValue(true);
  }

  create() {
    this.http.methodPost(`bo/quizmanage/create`, this.form.value).subscribe(res => {
      alert('สร้างคำถามแล้ว');
      this.back();
    })
  }

  update() {
    this.http.methodPut(`bo/quizmanage/update/${this.quizId}`, this.form.value).subscribe(res => {
      alert('แก้ไขคำถามแล้ว');
      this.back();
    })
  }

  back() {
    this.router.navigate(['/', appUrl.activity])
  }

}
