import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-block-message',
  templateUrl: './block-message.component.html',
  styleUrls: ['./block-message.component.scss']
})
export class BlockMessageComponent implements OnInit {

  constructor(private app: AppService,
    private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.getItems();
  }

  itemsMessage: any = []
  getItems() {
    this.http.methodGet(`bo/profanitymanage`).subscribe(res => {
      this.itemsMessage = res;
    })
  }

  addMessage() {
    this.router.navigate(['/', appUrl.block, 'add']);
  }

  delete(ms) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`bo/profanitymanage/${ms.ProfanityWordId}`).subscribe(res => {
        txt = "You pressed OK!";
        this.getItems();
      })
    } else {
      txt = "You pressed Cancel!";
    }

  }

}
