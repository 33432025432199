import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss']
})
export class AddMessageComponent implements OnInit {

  constructor(private app: AppService,
    private build: FormBuilder,
    private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.formControl();
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      ProfanityWord: ""
    })
  }

  back() {
    this.router.navigate(['/', appUrl.block])
  }

  addMessage() {
    this.http.methodPost(`bo/profanitymanage`, this.form.value).subscribe(res => {
      alert('เพิ่มข้อความแล้ว');
      this.back();
    })
  }

}
