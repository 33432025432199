import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
declare let $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BO';
  constructor(private app: AppService) {
    this.app.Authorization = localStorage.getItem('botk') || "";
    this.app.RoleCode = localStorage.getItem('rltk') || "";
  }

  ngOnInit() {

  }
}
