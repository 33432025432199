import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../shareds/services/http.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor(private http: HttpService) { }

  ngOnInit() {
  }

  exportTotal(date) {
    this.http.methodGetExport(`bo/report/quiz?onDate=${date.value}`).subscribe(res => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res);
      link.target = '_blank';
      link.click();
    })
  }

  exportLog() {
    this.http.methodGetExport(`bo/report/registerlog`).subscribe(res => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res);
      link.target = '_blank';
      link.click();
    })
  }

}
