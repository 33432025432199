import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-product-booth',
  templateUrl: './product-booth.component.html',
  styleUrls: ['./product-booth.component.scss']
})
export class ProductBoothComponent implements OnInit {

  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  bootname: string;
  bootid: number;
  active: string;
  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.getItemsProduct()
  }


  itemProduct: any = [];
  getItemsProduct() {
    this.http.methodGet(`bo/productmanage/find?boothId=${this.bootid}`).subscribe(res => {
      this.itemProduct = res;
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.boothmanage])
  }

  createProduct() {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active, 'add', 'product', '^^'])
  }

  viewDetail(items) {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active, 'update', 'product', items.ProductId])
  }


}
