import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-user-booth',
  templateUrl: './user-booth.component.html',
  styleUrls: ['./user-booth.component.scss']
})
export class UserBoothComponent implements OnInit {
  add: boolean = false;
  constructor(private http: HttpService,
    private router: Router,
    private app: AppService,
    public activatedRoute: ActivatedRoute,
    private meta: Meta,
    private title: Title) { }

  itemAdmin: any = [];
  bootname: string;
  bootid: number;
  active: string;
  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    // this.title.setTitle('ผู้ดูแลระบบ BACKOFFICE');
    // this.meta.addTags([
    //   { name: 'description', content: 'This is an article about Angular Meta service' },
    //   { name: 'keywords', content: 'angular, javascript, typescript, meta, seo' }
    // ])
    this.http.methodGet(`bo/users?userId=&email=&username=&fullname=&status=&roleId=&boothId=${this.bootid}`).subscribe(res => {
      this.itemAdmin = res;
    })
  }

  // addUser() {
  //   this.router.navigate(['/', appUrl.users, 'add']);
  // }

  addUsers() {
    this.router.navigate(['/', appUrl.users, 'add', this.bootname, this.bootid]);
  }



  detail(items: any) {
    if (items.UserRoleCode == 'AD') return alert('ต้องสิทธิ์ Admin เท่านั้น');
    this.router.navigate(['/', appUrl.users, 'update', this.bootname, this.bootid, items.UserId]);
  }
}
