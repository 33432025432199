import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';

@Injectable({
  providedIn: 'root'
})
export class UnautorizationGuard {
  constructor(private app: AppService, private router: Router) { }
  canActivate(): boolean | Observable<boolean> {
    if (!this.app.Authorization) return true;
    else { this.router.navigate(['/', appUrl.home]); return false }
  }

}
