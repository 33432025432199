import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  constructor(private app: AppService,
    private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.getItems();
  }

  addMessage() {
    this.router.navigate(['/', appUrl.activity, 'add']);
  }

  itemsAw: any = [];
  getItems() {
    this.http.methodGet(`bo/quizmanage/find`).subscribe(res => this.itemsAw = res)
  }

  update(items) {
    this.router.navigate(['/', appUrl.activity, 'update', items.QuizQuestionId]);
  }

  delete(items) {
    var txt;
    var delet = confirm("ลบนะ");
    if (delet == true) {
      this.http.methodDelete(`bo/quizmanage/delete/${items.QuizQuestionId}`).subscribe(res => {
        txt = "ลบแล้ว";
      })

    } else {
      txt = "ยกเลิก";
    }

  }

}
