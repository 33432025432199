import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-faq-booth',
  templateUrl: './faq-booth.component.html',
  styleUrls: ['./faq-booth.component.scss']
})
export class FaqBoothComponent implements OnInit {

  constructor(private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  bootname: string;
  bootid: number;
  active: string;
  itemFAQ: any = [];
  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.http.methodGet(`bo/faqmanage/find/${this.bootid}`).subscribe(res => {
      this.itemFAQ = res;
    })
  }

  addFAQ() {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active, 'add', 'faq', '^^'])
  }

  updateFAQ(items) {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active, 'update', 'faq', items.FAQId])
  }
}
