import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-booth',
  templateUrl: './booth.component.html',
  styleUrls: ['./booth.component.scss']
})
export class BoothComponent implements OnInit {

  constructor(private http: HttpService, private router: Router,
    private app: AppService,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle('จัดการบูธ BACKOFFICE');
    this.getBooth()

  }

  itemBooth: any = [];
  getBooth() {
    this.http.methodGet(`bo/boothmanage`).subscribe(res => {
      this.itemBooth = res;
    })
  }

  addBooth() {
    this.router.navigate(['/', appUrl.boothmanage, 'add'])
  }

  updateBooth(items) {
    this.router.navigate(['/', appUrl.boothmanage, 'update', items.BoothId])
  }

  Zone(str: number) {
    if (str == 1) return "Ai";
    if (str == 2) return "Big Data";
    if (str == 3) return "Ai & Big Data";
    if (str == 4) return "อื่นๆ";
  }

  detail(items) {
    this.router.navigate(['/', appUrl.boothmanage, items.BoothName, 'booth', items.BoothId, 'users']);
  }
}
