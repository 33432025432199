import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/shareds/services/http.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.scss']
})
export class LivestreamComponent implements OnInit {

  constructor(private http: HttpService,
    private build: FormBuilder) {

  }

  stream: any
  demo: string = `<iframe width="560" height="315" src="https://www.youtube.com/embed/M953xksaYGA" title="YouTube video player"
  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen></iframe>`
  ngOnInit() {
    this.http.methodGet(`bo/livestreammanage`).subscribe(res => {
      this.stream = res;
    })
  }

  changeName(items, name) {
    items.LiveStreamName = name.value
  }

  check(items) {
    items.UsePlayBack = !items.UsePlayBack;
  }

  playURL(items, url) {
    items.VideoPlayBackLink = url.value
  }

  updateLive(items) {
    let model = {
      "LiveId": items.LiveStreamId,
      "Title": items.LiveStreamName,
      "playBackLink": items.VideoPlayBackLink,
      "usePlayBack": items.UsePlayBack
    }
    this.http.methodPut(`bo/livestreammanage?liveId=${items.LiveStreamId}`, model).subscribe(res => {
      alert('บันทึกแล้ว')
    })
  }

}
