import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private app: AppService) { }

  location = 'https://api.thainavy.org';


  get headers() {
    let headers = new HttpHeaders;
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    if (this.app.Authorization) headers = headers.append('Authorization', `${this.app.Authorization}`);
    return headers;
  }

  private UploadFile(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.app.Authorization) headers = headers.append('Authorization', `${this.app.Authorization}`);
    return headers;
  }

  private get ExportHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/pdf');
    if (this.app.Authorization) headers = headers.append('Authorization', `${this.app.Authorization}`);
    return headers;
  }



  methodGet(url: string) {
    return this.http.get(`${this.location}/${url}`, { headers: this.headers });
  }

  methodGetExport(url: string) {
    return this.http.get(`${this.location}/${url}`, { headers: this.ExportHeader, responseType: 'blob' });
  }

  methodPostExport(url: string, model: any) {
    return this.http.post(`${this.location}/${url}`, model, { headers: this.ExportHeader, responseType: 'blob' });
  }

  uploadfilePOST(url: string, fileToUpload) {
    let formData: FormData = new FormData();
    for (let items in fileToUpload) {
      if (typeof fileToUpload[items] == "object") {
        let i = 0;
        if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
          if (f.status == 'create') formData.append(`${items}[${[i]}]`, f, f.name)
          if (f.status != 'create') {
            formData.append(`${items}[${[i]}].name`, f.name)
            formData.append(`${items}[${[i]}].status`, f.status)
          }
          i++;
        });


      }

      else formData.append(items, fileToUpload[items]);
    }
    return this.http.post(`${this.location}/${url}`, formData, { headers: this.UploadFile() });
  }

  uploadfilePUT(url: string, fileToUpload) {
    let formData: FormData = new FormData();
    for (let items in fileToUpload) {
      if (typeof fileToUpload[items] == "object") {
        let i = 0;
        if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
          if (f.status == 'create') formData.append(`${items}[${[i]}]`, f, f.name)
          if (f.status != 'create') {
            formData.append(`${items}[${[i]}].name`, f.name)
            formData.append(`${items}[${[i]}].status`, f.status)
          }
          i++;
        });

      }
      else formData.append(items, fileToUpload[items]);
    }
    return this.http.put(`${this.location}/${url}`, formData, { headers: this.UploadFile() });
  }

  methodPost(url: string, model: any) {
    return this.http.post(`${this.location}/${url}`, model, { headers: this.headers });
  }

  methodPut(url: string, model: any) {
    return this.http.put(`${this.location}/${url}`, model, { headers: this.headers });
  }

  methodDelete(url: string) {
    return this.http.delete(`${this.location}/${url}`, { headers: this.headers });
  }
}
