import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { appUrl } from './app.url';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  Authorization: string;
  Reload = new Subject();
  RoleCode: string;
  urlSignalr = "https://api.thainavy.org/signalr";
  vodURL: string = "https://video.thainavy.org/";
  imgURL: string = "https://images.thainavy.org/";
  constructor(private app: AppService, private router: Router,
    private activatedRoute: ActivatedRoute) { }

  logout(str?: string) {
    if (!str || str == "Authorization has been denied for this request.") {
      localStorage.removeItem('botk');
      localStorage.removeItem('rltk');
      this.Authorization = "";
      this.router.navigate(['/', appUrl.signin])
    }
  }
}
