import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';

@Injectable({
  providedIn: 'root'
})
export class AutorizationGuard {
  constructor(private app: AppService, private router: Router) { }
  canActivate(): boolean | Observable<boolean> {
    if (this.app.Authorization) return true;
    else { this.router.navigate(['/', appUrl.signin]); return false }
  }

}
