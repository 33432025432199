import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './a-main-component/home/home.component';
import { LoginComponent } from './a-main-component/login/login.component';
import { appUrl } from './app.url';
import { ActivityComponent } from './component/activity/activity.component';
import { AddActivityComponent } from './component/activity/add-activity/add-activity.component';
import { AddAdminComponent } from './component/admin/add-admin/add-admin.component';
import { AdminComponent } from './component/admin/admin.component';
import { AddMessageComponent } from './component/block-message/add-message/add-message.component';
import { BlockMessageComponent } from './component/block-message/block-message.component';
import { AddBoothComponent } from './component/booth/add-booth/add-booth.component';
import { BoothComponent } from './component/booth/booth.component';
import { DetailBoothComponent } from './component/booth/detail-booth/detail-booth.component';
import { AddFaqComponent } from './component/booth/detail-booth/faq-booth/add-faq/add-faq.component';
import { AddProductComponent } from './component/booth/detail-booth/product-booth/add-product/add-product.component';
import { ProductBoothComponent } from './component/booth/detail-booth/product-booth/product-booth.component';
import { AddUserChatComponent } from './component/chat/add-user-chat/add-user-chat.component';
import { ChatComponent } from './component/chat/chat.component';
import { LivestreamComponent } from './component/livestream/livestream.component';
import { AddRewardComponent } from './component/reward/add-reward/add-reward.component';
import { RewardComponent } from './component/reward/reward.component';
import { AutorizationGuard } from './shareds/guards/autorization.guard';
import { UnautorizationGuard } from './shareds/guards/unautorization.guard';
import { AddRandomComponent } from './component/reward/add-random/add-random.component';
import { ReportComponent } from './component/report/report.component';
import { StageComponent } from './component/stage/stage.component';
import { AddStageComponent } from './component/stage/add-stage/add-stage.component';

const routes: Routes = [
  { path: '', redirectTo: appUrl.signin, pathMatch: "full" },
  { path: appUrl.signin, component: LoginComponent, canActivate: [UnautorizationGuard] },
  { path: appUrl.home, component: HomeComponent, canActivate: [AutorizationGuard] },
  { path: appUrl.livestreammanage, component: LivestreamComponent, canActivate: [AutorizationGuard] },
  { path: appUrl.report, component: ReportComponent, canActivate: [AutorizationGuard] },
  {
    path: appUrl.users, canActivate: [AutorizationGuard], children: [
      { path: '', component: AdminComponent },
      { path: ':status', component: AddAdminComponent },
      { path: ':status/:id', component: AddAdminComponent },
      { path: ':status/:name/:booth', component: AddAdminComponent },
      { path: ':status/:name/:booth/:id', component: AddAdminComponent },
    ]
  },
  {
    path: appUrl.boothmanage, canActivate: [AutorizationGuard], children: [
      { path: '', component: BoothComponent },
      { path: ':status', component: AddBoothComponent },
      { path: ':status/:id', component: AddBoothComponent },
      { path: ':name/booth/:id', component: DetailBoothComponent },
      { path: ':name/booth/:id/:action', component: DetailBoothComponent },
      { path: ':name/booth/:id/:action/:status/product/:actionid', component: AddProductComponent },
      { path: ':name/booth/:id/:action/:status/faq/:actionid', component: AddFaqComponent },
    ]
  },
  {
    path: appUrl.mainchat, canActivate: [AutorizationGuard], children: [
      { path: '', component: ChatComponent },
      { path: ':status', component: AddUserChatComponent },
      { path: ':status/:id', component: AddUserChatComponent },
    ]
  },
  {
    path: appUrl.activity, canActivate: [AutorizationGuard], children: [
      { path: '', component: ActivityComponent },
      { path: ':status', component: AddActivityComponent },
      { path: ':status/:id', component: AddActivityComponent },
    ]
  },
  {
    path: appUrl.announcement, canActivate: [AutorizationGuard], children: [
      { path: '', component: RewardComponent },
      { path: ':status', component: AddRewardComponent },
      { path: ':status/:id', component: AddRewardComponent },
      { path: 'rw/random/start', component: AddRandomComponent },
    ]
  },
  {
    path: appUrl.block, canActivate: [AutorizationGuard], children: [
      { path: '', component: BlockMessageComponent },
      { path: ':status', component: AddMessageComponent },
      { path: ':status/:id', component: AddMessageComponent },
    ]
  },
  {
    path: appUrl.state, canActivate: [AutorizationGuard], children: [
      { path: '', component: StageComponent },
      { path: ':status', component: AddStageComponent },
      { path: ':status/:id', component: AddStageComponent },
    ]
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
