import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { HttpService } from '../../shareds/services/http.service';
import { appUrl } from '../../app.url';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {

  constructor(private app: AppService,
    private router: Router,
    private http: HttpService) { }

  items: any;
  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.http.methodGet(`bo/settingdatamanage/mainstate`).subscribe(res => {
      this.items = res;
    })
  }

  addVdo() {
    this.router.navigate(['/', appUrl.state, 'add'])
  }

  update(items) {
    this.router.navigate(['', appUrl.state, 'update', items.SettingDataId])
  }

  delete(items) {
    var txt;
    var delet = confirm("ต้องการลบ ?");
    if (delet == true) {
      this.http.methodDelete(`bo/settingdatamanage/mainstate?settingDataId=${items.SettingDataId}`).subscribe(res => {
        txt = "ลบแล้ว";
        this.getItems();
      })

    } else {
      txt = "ยกเลิก";
    }

  }
}
