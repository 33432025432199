import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-add-reward',
  templateUrl: './add-reward.component.html',
  styleUrls: ['./add-reward.component.scss']
})
export class AddRewardComponent implements OnInit {

  constructor(private app: AppService,
    private build: FormBuilder,
    private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.formControl();
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      rewardName: "",
      receivedName: "",
      receivedPhoneNo: "",
    })
  }

  subCreate() {
    this.http.methodPost(`bo/rewardreceived?rewardName=${this.form.value['rewardName']}&receivedName=${this.form.value['receivedName']}&receivedPhoneNo=${this.form.value['receivedPhoneNo']}`, null).subscribe(res => {
      console.log(res);
      this.back();
    })
  }

  back() {
    this.router.navigate(['/', appUrl.announcement])
  }
}
