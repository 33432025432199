import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {

  constructor(private build: FormBuilder,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  form: FormGroup;
  bootname: string;
  bootid: number;
  status: string;
  active: string;
  faqid: number;
  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.status = this.activatedRoute.snapshot.params['status'];
    this.faqid = this.activatedRoute.snapshot.params['actionid'];

    this.formControl();
    if (this.faqid) this.getItemsFAQ();
  }

  getItemsFAQ() {
    this.http.methodGet(`bo/faqmanage/get/${this.faqid}`).subscribe(res => {
      this.form.patchValue(res)
    })
  }


  formControl() {
    this.form = this.build.group({
      BoothId: this.bootid,
      Question: "",
      Answer: ""
    })
  }

  createFAQ() {
    this.http.methodPost(`bo/faqmanage`, this.form.value).subscribe(res => {
      alert('สร้าง FAQ แล้ว')
      this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
    })
  }

  updateFAQ() {
    this.http.methodPut(`bo/faqmanage/${this.faqid}`, this.form.value).subscribe(res => {
      alert('แก้ไข FAQ แล้ว')
      this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
  }

}
