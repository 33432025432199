import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpService } from '../../../shareds/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { appUrl } from '../../../app.url';

@Component({
  selector: 'app-add-stage',
  templateUrl: './add-stage.component.html',
  styleUrls: ['./add-stage.component.scss']
})
export class AddStageComponent implements OnInit {

  constructor(private build: FormBuilder,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }
  demo: string = `<iframe width="560" height="315" src="https://www.youtube.com/embed/M953xksaYGA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  id: number;
  items: any = [];
  ngOnInit() {
    this.formControl();
    this.id = this.activatedRoute.snapshot.params['id'];
    if (this.id) this.http.methodGet(`bo/settingdatamanage/mainstate`).subscribe(res => {
      this.items = res;
      let form = this.items.find(fn => fn.SettingDataId == this.id);
      this.formVdo.get('Name').setValue(form.SettingDataName);
      this.formVdo.get('Value').setValue(form.SettingDataValue);
    })
  }

  formVdo: FormGroup;
  formControl() {
    this.formVdo = this.build.group({
      "Name": "",
      "Detail": "",
      "Value": ""
    })
  }

  createVdo() {
    this.http.methodPost(`bo/settingdatamanage/mainstate`, this.formVdo.value).subscribe(res => {
      alert('สร้าง VDO แล้ว');
      this.back();
    })
  }

  updateVdo() {
    this.http.methodPut(`bo/settingdatamanage/mainstate/${this.id}`, this.formVdo.value).subscribe(res => {
      alert('แก้ไข VDO แล้ว');
      this.back();
    })
  }

  back() {
    this.router.navigate(['/', appUrl.state]);
  }

}
