import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss']
})
export class RewardComponent implements OnInit {

  constructor(private app: AppService,
    private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.getItems();
  }

  addReward() {
    this.router.navigate(['/', appUrl.announcement, 'add']);
  }

  addRandom() {
    this.router.navigate(['/', appUrl.announcement, 'rw', 'random', 'start']);
  }

  reawardItems: any = [];
  getItems() {
    this.http.methodGet(`bo/rewardmanage/customerreceived`).subscribe(res => {
      this.reawardItems = res;
    })
  }

  delete(rw) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`bo/rewardmanage/delete/${rw.CustomerId}`).subscribe(res => {
        txt = "You pressed OK!";
        this.getItems();
      })

    } else {
      txt = "You pressed Cancel!";
    }
  }

  view(items) {
    this.http.methodPut(`bo/rewardmanage/customerreceived/show/${items.RewardId}`, null).subscribe(res => {
      items.ShowHallOfFrame = !items.ShowHallOfFrame;
    })
  }

}
