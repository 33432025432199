import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private http: HttpService,
    private router: Router,
    private app: AppService,
    private activatedRoute: ActivatedRoute) { }

  itemsNavbar: any = [];
  activeNavbar: string;
  ngOnInit() {
    this.app.Reload.subscribe(res => this.getNavBar());
    this.getNavBar();
  }

  getNavBar() {
    this.activeNavbar = window.location.pathname;
    this.http.methodGet("bo/modules/get_by_role").subscribe(res => {
      this.itemsNavbar = res;
      let path = this.itemsNavbar.find(fn => fn.ModulePath == this.activeNavbar);
      if (!path || path == undefined) {
        if (this.activeNavbar == `/${appUrl.home}` || this.activeNavbar == `/${appUrl.signin}` || this.activeNavbar == '/') {
          this.activeNavbar = this.itemsNavbar[0].ModulePath;
          document.getElementById("activeName").innerHTML = this.itemsNavbar.find(fn => fn.ModulePath == this.activeNavbar).ModuleName;
          this.router.navigate([this.activeNavbar]);
        }
      }
      if (path) document.getElementById("activeName").innerHTML = this.itemsNavbar.find(fn => fn.ModulePath == this.activeNavbar).ModuleName;
    }, err => this.app.logout(err.error.Message))
  }


  navbar: boolean = true;
  navbarFunction(navbar) {
    this.navbar = !navbar;
    var x = document.getElementById("myTopnav");
    if (!this.navbar) x.className = "topnav responsive";
    else x.className = "topnav";

  }

  changePage(items) {
    this.navbar = true;
    this.activeNavbar = items.ModulePath;
    document.getElementById("myTopnav").className = "topnav";
    setTimeout(() => document.getElementById("activeName").innerHTML = `${items.ModuleName}`, 0);
    this.router.navigate([items.ModulePath]);
  }

  logout() {
    this.app.logout();
  }


}
