import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';


@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {
  // @Output() update = new EventEmitter();
  constructor(private build: FormBuilder,
    private router: Router,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private app: AppService) { }

  userid: number;
  booth: any;
  name: string;
  ngOnInit() {
    this.formControl();
    this.userid = this.activatedRoute.snapshot.params['id'];
    this.name = this.activatedRoute.snapshot.params['name'];
    this.booth = this.activatedRoute.snapshot.params['booth'];


    if (this.userid) this.http.methodGet(`bo/users/${this.userid}`).subscribe(res => this.form.patchValue(res))
    if (this.booth) {
      this.form.get('BoothId').setValue([parseInt(this.booth)]);
      this.form.get('UserRoleId').setValue(0);
    }
  }


  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      BoothId: [""],
      UserRoleId: 2,
      UserFullName: "",
      // UserEmail: "",
      UserUsername: "",
      UserPassword: "",
      ConfirmPassword: "",
      NewPassword: "",
    })
  }

  selectItems: any[] = [
    { id: 3, name: 'หัวหน้าบูธ' },
    { id: 4, name: 'พนักงานบูธ' },
  ];


  cancel() {
    if (!this.booth) this.router.navigate(['/', appUrl.users])
    else this.router.navigate(['/', appUrl.boothmanage, this.name, 'booth', this.booth, 'users'])
  }

  createAdmin() {
    this.form.get('UserRoleId').setValue(parseInt(this.form.value['UserRoleId']))
    if (this.form.value['UserPassword'] != this.form.value['ConfirmPassword']) alert('Password ไม่ตรงกัน');
    this.http.methodPost(`bo/users`, this.form.value).subscribe(res => {
      if (!this.booth) {
        alert('สร้าง Admin แล้ว');
        this.router.navigate(['/', appUrl.users]);
      }
      else {
        alert('สร้าง Users');
        this.router.navigate(['/', appUrl.boothmanage, this.name, 'booth', this.booth, 'users'])
      }

    })
  }

  updateAdmin() {
    if (this.form.value['UserPassword'] != this.form.value['ConfirmPassword']) return alert('Password ไม่ตรงกัน');

    this.http.methodPut(`bo/users/${this.userid}`, this.form.value).subscribe(res => {
      if (!this.booth) {

        if (this.form.value['UserPassword']) {
          this.form.get('NewPassword').setValue(this.form.value['UserPassword']);
          this.http.methodPut(`bo/users/change_password/${this.userid}`, this.form.value).subscribe(res => {
            alert('อัพเดทแล้ว');
            this.router.navigate(['/', appUrl.users]);
          })
        } else {
          alert('อัพเดทแล้ว');
          this.router.navigate(['/', appUrl.users]);
        }

      }
      else {
        alert('อัพเดทแล้ว');
        this.router.navigate(['/', appUrl.boothmanage, this.name, 'booth', this.booth, 'users'])
      }
    })
  }
}
