export const appUrl = {
    home: "home",
    signin: "signin",
    users: "users",
    boothmanage: "boothmanage",
    livestreammanage: "livestreammanage",
    mainchat: "mainchat",
    activity: "activity",
    announcement: "announcement",
    reward: "reward",
    block: "dontuseprofanity",
    report: "report",
    state: "mainstate",
}