import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpService } from '../../../shareds/services/http.service';
import { appUrl } from '../../../app.url';

@Component({
  selector: 'app-add-random',
  templateUrl: './add-random.component.html',
  styleUrls: ['./add-random.component.scss']
})
export class AddRandomComponent implements OnInit {

  height: any;
  reloading: boolean;
  typeItems: any = [];
  constructor(
    private build: FormBuilder,
    private router: Router,
    private http: HttpService) {
    this.http.methodGet(`bo/rewardmanage/customerreceived`).subscribe(res => {
      console.log(res);
      this.typeItems = res;
    })
  }
  ngOnInit(): void {
    this.formControl();
    this.height = window.innerHeight;
  }

  form: FormGroup;
  formSend: FormGroup
  formControl() {
    this.form = this.build.group({
      num1: [0],
      num2: [0],
      num3: [0],
      num4: [0],
      num5: [0],
      num6: [0],
      num7: [0],
      num8: [0],
      num9: [0],
      num10: [0],
      numLoading: [0]

    });

    this.formSend = this.build.group({
      RewardId: 1,
      CustomerId: 2
    })
  }

  number: any;
  startRandom: boolean = false
  randomNumber() {
    this.startRandom = true;
    setInterval(res => { if (this.startRandom) this.form.get('numLoading').setValue(Math.floor(Math.random() * 10)) }, 50)
    this.form.reset()
    setTimeout(() => {
      this.http.methodGet(`bo/rewardmanage/random`).subscribe(res => {
        this.number = res;
        this.formSend.get('CustomerId').setValue(this.number['CustomerId'])
        let loading = 300;
        for (let i in this.number['CustomerPhoneNo']) {
          setTimeout(() => {
            let num = `num${parseInt(i) + 1}`;
            this.form.get(num).setValue(this.number['CustomerPhoneNo'][i])
          }, loading += 200);
        }
      })
    }, 500);
  }


  svaeNumber() {
    this.http.methodPost(`bo/rewardmanage/create`, this.formSend.value).subscribe(res => {
      alert('บันทึกแล้ว')

    })
  }

  prizeTypeId: any;
  selectType(v) {
    this.prizeTypeId = v.value;
    this.formSend.get('RewardId').setValue(this.prizeTypeId)
  }


  showDialog() {
    this.router.navigate(['/', appUrl.announcement])
  }
}
