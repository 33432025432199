import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  add: boolean = false;
  constructor(private http: HttpService,
    private router: Router,
    private app: AppService,
    public activatedRoute: ActivatedRoute,
    private meta: Meta,
    private title: Title) { }

  itemAdmin: any = [];
  ngOnInit() {
    this.title.setTitle('ผู้ดูแลระบบ BACKOFFICE');
    // this.meta.addTags([
    //   { name: 'description', content: 'This is an article about Angular Meta service' },
    //   { name: 'keywords', content: 'angular, javascript, typescript, meta, seo' }
    // ])
    this.http.methodGet(`bo/users?userId=&email=&username=&fullname=&status=&roleId=&boothId=`).subscribe(res => {
      this.itemAdmin = res;
    })
  }

  addUser() {
    this.router.navigate(['/', appUrl.users, 'add']);
  }



  detail(items: any) {
    this.router.navigate(['/', appUrl.users, 'update', items.UserId]);
  }

}
